import { FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Input, Textarea, FormErrorMessage, Select } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import SecondaryButton from '../../components/themeComponents/SecondaryButton'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'

export default function SaveTemplateModal({ isOpen, onClose, formik, uuid }) {
  const templateTypes = [
    {
      key: 'Body',
      value: 'body'
    },
    {
      key: 'Header',
      value: 'header'
    },
    {
      key: 'Footer',
      value: 'footer'
    }
  ]
  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay></ModalOverlay>
        <ModalContent>
          <ModalHeader>{!!uuid ? 'Update' : 'Save'} Template</ModalHeader>
          <ModalCloseButton></ModalCloseButton>
          <ModalBody>
            <FormControl mb={3} isRequired isInvalid={formik.errors.description && formik.touched.description}>
              <FormLabel color={'inputLabel'} mb={0}>Name</FormLabel>
              <Input name='description' placeholder="Template Name" value={formik.values.description} onChange={formik.handleChange}></Input>
              {
                formik.errors.description && formik.touched.description && <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
              }
            </FormControl>

            <FormControl mb={3} isRequired isInvalid={formik.errors.subject && formik.touched.subject}>
              <FormLabel color={'inputLabel'} mb={0}>Subject Line</FormLabel>
              <Textarea name='subject' placeholder="Enter subject line" value={formik.values.subject} onChange={formik.handleChange}></Textarea>
              {
                formik.errors.subject && formik.touched.subject && <FormErrorMessage>{formik.errors.subject}</FormErrorMessage>
              }
            </FormControl>
            <FormControl mb={3} >
              <FormLabel color={'inputLabel'} mb={0}>Preheader Text</FormLabel>
              <Input name='preheader' placeholder="Enter preheader text here" value={formik.values.preheader} onChange={formik.handleChange}></Input>
            </FormControl>
            <FormControl mb={3} >
              <FormLabel color={'inputLabel'} mb={0}>Type  </FormLabel>
              <Select defaultValue={templateTypes[0].key} name='type' placeholder="Select Template Type" value={formik.values.type} onChange={formik.handleChange}>
                {
                  templateTypes.map((type) => {
                    return <>
                      <option value={type.value} >
                        {type.key}
                      </option>
                    </>
                  })

                }
              </Select>
            </FormControl>

          </ModalBody>
          <ModalFooter>
            <SecondaryButton label={'Cancel'} mr={2}></SecondaryButton>
            <PrimaryButton label={!!uuid ? 'Update' : 'Save'} onClick={formik.handleSubmit}></PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  )
}
