import React, { Fragment, useEffect, useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { UPDATE_REACH } from '../../../store/Reducers/CampaignReducer';
const Reach = React.memo(({ audience, query, setTotalReach , selectedChannel,subscribersCountQuery}) => {
    
    const dispatch = useDispatch();

    const reach = useMemo(() => {
        
        let count = 0;
        // if(subscribersCountQuery.data>0){
        //     count += subscribersCountQuery.data
        // }
        let selectedCSV = audience?.CSV || audience?.csv;
        let selectedSegment = audience?.segment;
        
        !!selectedSegment?.length && selectedSegment?.map(seg => {
            if (seg?.segmentType === 'sendToAll') {
                count = count + query.data
                count = process.env.NODE_ENV === 'development' ? count + 2 : count
            }
        })
        !!selectedSegment?.length && selectedSegment?.map(segment => {
            selectedChannel=='email' && segment?.aggregates?.count_email && (count = count + segment?.aggregates?.count_email)
        })

        !!selectedSegment?.length && selectedSegment?.map(segment => {
            selectedChannel!='email' && segment?.aggregates?.count_phone && (count = count + segment?.aggregates?.count_phone)
        })
        !!selectedCSV?.length && selectedCSV?.map(csv => {
            csv?.data?.extraData?.validationData?.counts?.estimated_reach && (count = count + csv.data.extraData.validationData.counts.estimated_reach)
        })
        // console.log(count,"count bfore dispatch")
        dispatch(UPDATE_REACH({count: count}))
        // console.log(count,"count after dispatch")
        setTotalReach(count)
        return count
    }, [audience, query, selectedChannel, dispatch, setTotalReach,subscribersCountQuery])
    // console.log(reach,":::reach")
   
    return (
        <Fragment>
            <Text fontSize={'md'} as={'b'}>Reachability </Text>
            {/* {JSON.stringify(reach)} */}
            <Text>Approximately <b>{reach}</b> people will receive this campaign.</Text>
            <Text>Duplicate entries will not be targeted.</Text>
        </Fragment>
    )
})

export default Reach
