import React, { useState, useEffect } from 'react';
import { Box, Text, FormLabel, HStack, VStack , Input} from '@chakra-ui/react';
import Select from '../../components/themeComponents/react-chakra-select';
import store from '../../store/store';
import { prepareOrgList } from '../../_helpers/data_helper';
import { getEmailConf } from '../../Services/api';


export default function OrgAndConfig({ formik }) {
  const [selectedOrgList, setSelectedOrgList] = useState([]); // State to hold selected values
  const [emailConfiguration, setEmailConfiguration] = useState([]);
  const [orgOptions, setOrgOptions] = useState([]); // State to hold the options list

  // Fetch email configurations based on token
  const emailConf = async (token) => {
    try {
      const res = await getEmailConf(token);
      setEmailConfiguration((prevConfig) => [...prevConfig, res.data]);
    } catch (err) {
      console.error(err);
    }
  };

  
  const handleSelectChange = (selectedOptions) => {
    setSelectedOrgList(selectedOptions); 
    if (selectedOptions.length) {
      const latestToken = selectedOptions[selectedOptions.length - 1].value;
      emailConf(latestToken);
      formik.setFieldValue(
        'orgList',
        selectedOptions.map((e) => e.value)
      );
    }
  };




  return (
    <Box maxW="1100px">
      <Text fontSize="1vw">Organisation </Text>
      <Box mt="1rem">
        <form>
          <Text>
            Campaign Name
          </Text>
          <Input type={"text"} name={'campaignName'} value={formik.values.campaignName} onChange={formik.handleChange} />
          <Text>Bank</Text>
          {/* <MultiSelect
          options={prepareOrgList(store.getState().org.orgList) }
          // label="Fruits"
          // value={selectedOptions}
          // onChange={handleSelectChange}
        /> */}
          <Select isMulti options={prepareOrgList(store.getState().org.orgList)} 
          onChange={handleSelectChange}
           closeMenuOnSelect={false} colorScheme='brand' 
           
             isClearable={false}></Select>
        </form>
      </Box>
      <FormLabel>Configuration</FormLabel>
      <VStack bg="#fff" w="100%">
        <HStack justifyContent="space-between" w="100%" px="2rem">
          {['Bank', 'From', 'Configuration Type'].map((value) => (
            <Text key={value}>{value}</Text>
          ))}
        </HStack>
        {emailConfiguration.length > 0 ? (
          emailConfiguration.map((config, i) => (
            <HStack key={i} justifyContent="space-between" w="100%" px="2rem">
              <Text>{config?.orgName || selectedOrgList[i]?.data?.name || 'N/A'}</Text>
              <Text>
                {config?.from?.split('<')[1]?.split('>')[0] || 'N/A'}
              </Text>
              <Text>{config ? 'Promotional' : 'N/A'}</Text>
            </HStack>
          ))
        ) : (
          <Text>No configurations available</Text>
        )}
      </VStack>
    </Box>
  );
}
