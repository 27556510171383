import { Box, FormLabel, HStack, Text, useToast, VStack } from '@chakra-ui/react'
import React from 'react'
import CampaignDraftDrawer from '../Campaigns/CampaignDraftDrawer'
import { useState, useEffect } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import store from '../../store/store'
import Select from '../../components/themeComponents/react-chakra-select'
import { prepareOrgList, prepareTargetedAudienceData } from '../../_helpers/data_helper'
import { useSteps } from '@chakra-ui/react'
import { targetAudienceSteps } from '../../_helpers/data_helper'
import CampaignStepper from '../Campaigns/components/CampaignStepper'
import OrgAndConfig from './OrgAndConfig'
import { useFormik } from 'formik'
import AudieceSelection from './AudieceSelection'
import * as _ from "lodash";
import { targetGroupCampaign } from '../../_helpers/formValues'
import TemplateSelectionTG from './TemplateSelectionTG'
import ScheduleCampaign from './ScheduleCampaign'
import { useNavigate } from 'react-router-dom'
import { draftCampaign, draftCampaignAllbanks, publishCampaign, publishCampaignAllBanks } from '../../Services/api'
import OrgAndConfigTG from './OrgAndConfigTG'

import Swal from 'sweetalert2';
// import AudieceSelection from './AudieceSelection'
export default function TargetGroup() {
  const [isOpen, setIsOpen] = useState(false)
  const [targetGroupData, setTargetGroupData] = useState({})
  const [campaignName, setCampaignName] = useState("")
  const [scheduleData, setScheduleData] = useState({});
  const [templatesData, setTemplatesData] = useState({});
  const initializeScheduleData = (token) => {
    setScheduleData(prev => ({
      ...prev,
      [token]: {
        scheduleStatus: 'NOW',
        scheduleAt: new Date(),
        repeatAfterDays: 1,
        repeatStopDateTime: new Date()
      }
    }));
  };



  const formik = useFormik({
    initialValues: targetGroupCampaign,
    enableReinitialize: true,
    onSubmit: () => { }
  })

  const navigate = useNavigate()
  const toast = useToast()
  const { activeStep, setActiveStep } = useSteps({
    index: 0, count: targetAudienceSteps.length
  })


  const saveAndPublishCampaign = async (values, action) => {
    let campaignData = values;


    Swal.fire({
      title: 'Are you sure?',
      text: "You are about to save the campaign!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!'
    }).then(async (result) => {
      if (result.isConfirmed) {

        const draftResponse = await draftCampaignAllbanks("", campaignData);
        if (draftResponse.data) {
          let publishResponse = await publishCampaignAllBanks(draftResponse.data, campaignData);


          if (!!publishResponse) {
            toast({
              title: `Campaign published successfully.`,
              status: 'success',
              duration: 3000,
              isClosable: true
            });
            navigate("/campaign/list");
          }


          if (publishResponse.data.result !== 'success') {
            toast({
              title: `${publishResponse.data.result}. Please check and try again.`,
              status: 'error',
              duration: 3000,
              isClosable: true
            });
          }
        }
      }
    });
  };

  useEffect(() => {

    if (Array.isArray(formik.values.orgList) && formik.values.orgList.length > 0) {
      const obj = {};
      formik.values.orgList.forEach((org) => {
        obj[org] = { csv: [], segment: [] };
      });
      setTargetGroupData(obj);
    }
    formik?.values?.orgList.forEach((org) => {
      initializeScheduleData(org)
    })
    console.log(scheduleData)
  }, [formik.values.orgList]);


  let prepareFinalData = () => {
    let retVal = {};

    formik.values.orgList.map((org) => {
      retVal[org] = {
        "campaignName": formik.values.campaignName,
        "targetedAudience": prepareTargetedAudienceData(targetGroupData[org]),
        "orgToken": org,
        "selectedChannel": "email",
        "selectedSmtpType": "promotional",
        "selectedTempName": templatesData[org]?.header?.label,
        "addCountryCode": true,
        "broadcastType": "CONTROL_GROUP",
        "selectedTempHeader": templatesData[org]?.header?.uuid || "",
        "selectedTempFooter": templatesData[org]?.footer?.uuid || "",
        "selectedTemp": templatesData[org]?.body?.uuid || "",
        "broadcastBatchWise": "true",
        "broadcast": "CONTROL_GROUP",
        "addToContactList": true,
        "broadcastType": "BROADCAST",
        "message": "",
        "isUnicode": false,
        "sentSmsCount": 1,
        "coupon": "",
        "mediaUrl": "",
        "approvedTemplateId": "",
        "selectedGateway": "",
      };
      retVal[org] = { ...retVal[org], ...scheduleData[org] }
    });

    console.log(Object.values(retVal));
    return Object.values(retVal);
  };

  useEffect(() => {
    console.log("targetGrpDtaa", targetGroupData);
    console.log("templatesData", templatesData)
    console.log("scheduleData", scheduleData)

  }, [targetGroupData, templatesData, scheduleData]);


  return (
    <Box marginLeft={'1rem'} overflowY={'scroll'} padding={'2rem'} borderRadius={'1vw'} h={"100%"} bg={'#EEEEEE'} alignItems={'baseline'} border={'1px solid #bebcbc'} >
      <Box  >
        <CampaignStepper steps={targetAudienceSteps} activeStep={activeStep}></CampaignStepper>
        <Box mt={'2rem'} >
          {
            activeStep === 0 &&
            <OrgAndConfig formik={formik} />
            // <OrgAndConfigTG/>
          }
        </Box>
        {
          activeStep === 1 &&
          <AudieceSelection setTargetGroupData={setTargetGroupData} targetGroupData={targetGroupData} formik={formik} />
        }
        {
          activeStep === 2 &&
          <TemplateSelectionTG setTemplatesData={setTemplatesData} templatesData={templatesData} formik={formik} />
        }
        {
          activeStep === 3 && <>
            {
              formik.values.orgList.map((org) => (
                <ScheduleCampaign scheduleData={scheduleData} setScheduleData={setScheduleData} initializeScheduleData={initializeScheduleData} orgToken={org} setTargetGroupData={setTargetGroupData} targetGroupData={targetGroupData} formik={formik} />
              ))
            }
          </>
        }
        <HStack pos={"fixed"} bottom={'2rem'} right={'2rem'} >

          {activeStep > 0 && <PrimaryButton label={'Back'} onClick={() => { setActiveStep(activeStep - 1) }} />}
          <PrimaryButton label={activeStep == 3 ? 'Publish' : 'Save & Continue'} onClick={() => {
            if (activeStep == 3) {

              saveAndPublishCampaign(prepareFinalData(), {})
            }else{
              setActiveStep(activeStep + 1)  
            }

          }
          } />
        </HStack>
      </Box>
    </Box>

  )
}
