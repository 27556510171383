import React from 'react'

import { Box, Image, Text, Link, IconButton, HStack, VStack } from '@chakra-ui/react';
import { CloseIcon, SettingsIcon } from '@chakra-ui/icons';
import store from '../../store/store';
export default function WebPushPreview({formData}) {
  return (
    <Box  borderRadius="md"  bg={'#fff'} p={4} boxShadow="lg" w={'100%'} >
        {/* {JSON.stringify(formData)} */}
    {/* Close button */}
    <IconButton
      aria-label="Close notification"
      icon={<CloseIcon />}
      variant="ghost"
      position="absolute"
      top="4px"
      right="4px"
    />

    {/* Notification image */}
    <HStack>

    <Box mb={4}>
      
      {!!formData?.iconUrl && (
          <Image
          src={formData?.iconUrl}
          alt="Notification Icon"
          width="80px"
          // mx="auto"
          />
        ) }
    </Box>

    {/* Notification text container */} 
    <VStack align="start" width={'100%'} spacing={2} mb={4}>
      <Text fontSize="lg" fontWeight="bold">
        {formData?.title || 'Notification Title Here'}
      </Text>
      <Text fontSize="sm">
        {formData?.body || 'Notification Message Here'}
      </Text>

    {/* Notification URL container */}
    <HStack justify="space-between"w={'100%'} align="center">
      <Link href={formData?.url || '#'} fontSize="sm" color="blue.500">
        {store.getState().auth.selectedOrg.domain}
      </Link>
      <IconButton
        aria-label="Settings"
        icon={<SettingsIcon />}
        size="sm"
        variant="ghost"
        />
        </HStack>
        {
          !!formData.imageUrl && !!formData.isImageBasedNotification &&
        <Image src={formData.imageUrl} />
        }
        </VStack>
    </HStack>
  </Box>
  )
}
