import { Box, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import store from '../../store/store'
import { useState } from 'react'
import TemplateSelection from './TemplateSelection'
import { fetchTemplate, fetchTemplateTG } from '../../Services/api'
import { useQuery } from 'react-query'
export default function TemplateSelectionTG({ formik, templatesData,setTemplatesData }) {

    const [orgList, setOrgList] = useState(store.getState()?.org?.orgList)
    const templateQuery = useQuery(['fetchTemplate'], {
        queryFn: async () => {
            let res = await fetchTemplateTG(formik.values.selectedChannel, formik.values.orgList)
            
            return res.data
        },
        enabled: true
    })

    useEffect(() => {
        templateQuery.refetch()
        setOrgList(orgList.filter((org) => {
            return formik.values.orgList.includes(org.token)
        }))
        console.log(orgList)

    }, [])
    return (
        <>
            {
                !!templateQuery?.data?.length && templateQuery?.data.map((e, i) => (

                    <Box>
                        <Text>
                            {/* {org.name} */}
                            <TemplateSelection templatesData={templatesData} setTemplatesData={setTemplatesData} templateQuery={e} selectedChannel={"email"} orgToken={e.org_token} formik={formik} />
                        </Text>

                        {/* <Text>
        {JSON.stringify(targetGroupData) }
    </Text> */}
                    </Box>
                ))
            }
        </>
    )
}
