import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Flex, FormControl, FormLabel, Checkbox, Text, Box, useDisclosure, Slider, SliderFilledTrack, SliderTrack, SliderThumb, Tooltip, Divider, HStack, VStack, Stack } from "@chakra-ui/react";
import Select from '../../components/themeComponents/react-chakra-select';
//import Select, { components } from "react-select";
import createReactClass from 'create-react-class';
import { useQuery, useQueryClient } from 'react-query';
import { getPredefinedSegmentCount, getSegmentCounts, getSegmentList, getTotalSubscribers, getUploadedFiles, getUploadedFilesAllBanks, updateSegment, getSegmentListAllBanks } from '../../Services/api';
import { prepareAllBankCSVList, prepareCSVList, prepareSegmentList } from '../../_helpers/data_helper';
import { OvalSpinner } from '../../components/Loaders';
import UploadCsvModal from '../../Pages/Campaigns/components//UploadCsvModal';
import { useDispatch, useSelector } from 'react-redux';
// import { getTargetAudienceTypes } from '../../_helpers/helpers';
import _ from 'lodash'
import { checkForInvalidCsv, extractSelectedCsvFromTargetAudience, calculateReach, updateCsvListAfterValidation } from '../../_helpers/data_helper';
import store from '../../store/store';
import CsvValidatorModal from '../../Pages/Campaigns/components/CsvValidatorModal';
import Reach from '../Campaigns/components/Reach';
// import Reach from './Reach';

// import { MdGraphicEq } from 'react-icons/md';
// import { segmentDummyData } from './segmentDummyData';
// import { Checkbox, CheckboxGroup } from '@chakra-ui/react'

function AudienceSelectionTG({ targetGroupData, setTargetGroupData, selectedChannel, orgToken, orgId, reach, formik, showSliders, setShowSlider, index, uploadedFilesQuery }) {

    const [totalReach, setTotalReach] = useState(0)
    const [reachToolTip, setReachToolTip] = useState(false)
    const [customSegmentCounts, setCustomSegmentCounts] = useState([])
    const queryClient = useQueryClient();
    const [sliderValue, setSliderValue] = useState({ CSV: [], segment: [] });
    // const sliderValue = {
    //     CSV:[],
    //     segment:[]
    // }
    const [actualReach, setActualReach] = useState({ CSV: [], segment: [] });
    const [orgList, setOrgList] = useState(store.getState()?.org?.orgList)
    const [selectedCsv, setSelectedCsv] = useState([])
    const invalidCsvCount = useSelector(state => state.campaign.invalidCsvCount)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isValidationModalOpen, onOpen: onValidationModalOpen, onClose: onValidationModalClose } = useDisclosure()
    const dispatch = useDispatch();
    const subscribersCountQuery = useQuery('subscriberCount', {
        queryFn: async () => {
            let res = await getTotalSubscribers(orgToken.org_token, selectedChannel);
            return res.data
        },
        //enabled: false,
        staleTime: 0
    })
    let segmentCount = {
        predefined: [],
        custom: [],
        rfm: []
    };

    const segmentCountQuery = useQuery('segmentCounts', {
        queryFn: async () => {
            let segmentCounts = await getSegmentCounts(orgId)
            if (segmentCounts.status == 200) {
                segmentCount['custom'] = segmentCounts.data
            }

            return segmentCounts.data
        },
        enabled: true,
        staleTime: 0
    })
    const segmentQuery = useQuery('segments', {
        queryFn: async () => {

            //let predefined = await getSegmentList(orgToken, 'predefined', orgId)
            let rfm = await getSegmentList(orgToken.org_token, 'rfm', orgId)
            let orgArr = orgList.filter((org) => {
                
                return formik.values.orgList.includes(org.token)

            })
            let org_ids = orgArr.map((e) => {
                return e.id
            })
            let custom = await getSegmentListAllBanks(orgToken.org_token, 'custom', formik.values.orgList)
            rfm.data.data = rfm.data.data.concat(custom.data.data[index].data)
            // debugger
            // return custom.data
            
            return prepareSegmentList(rfm.data.data)
        },
        enabled: true,
        staleTime: 0
    })

    const customSegmentQuery = useQuery('segmentsCustom', {
        queryFn: async () => {
            //let predefined = await getSegmentList(orgToken, 'predefined', orgId)
            // let custom = await updateSegment(orgToken, 'custom', orgId)
            let custom = await getSegmentList(orgToken.org_token, 'custom', orgId)

            return prepareSegmentList(custom.data)
        },
        enabled: false,
        staleTime: 0
    })




    const predefinedSegmentCountQuery = useQuery('predefinedSegmentCount', {
        queryFn: async () => {
            let res = await getPredefinedSegmentCount(orgToken.org_token);
            return res.data;
        },
        staleTime: 0,
        enabled: false
    })
    const onSegmentSelect = (selectedValues, e) => {
        let filteredSegments = [];
        
        if (!_.isEmpty(segmentCountQuery.data)) {
            
            !!segmentCountQuery?.data?.data.length && segmentCountQuery?.data?.data?.forEach((segment) => {
                
                if (!_.isEmpty(segment.aggregates) && segment?.id === e?.option?.data?.id && e.action !== 'remove-value') {
                    
                    filteredSegments.push(segment);
                    
                    setCustomSegmentCounts([...customSegmentCounts, filteredSegments[0]]);

                    setTargetGroupData((prevData) => ({
                        ...prevData,
                        [orgToken.org_token]: {
                            ...prevData[orgToken.org_token],
                            segment: selectedValues,
                        },
                    }));
                }
            });
        }

        let updatedCustomSegmentCounts = customSegmentCounts;
        if (!_.isEmpty(filteredSegments)) {
            updatedCustomSegmentCounts = [...updatedCustomSegmentCounts, filteredSegments[0]];
        }
        if (e.action === 'remove-value' && updatedCustomSegmentCounts.length > 0) {
            updatedCustomSegmentCounts = updatedCustomSegmentCounts.filter((value) => value.id !== e.removedValue.id);
            setTargetGroupData((prevData) => ({
                ...prevData,
                [orgToken.org_token]: {
                    ...prevData[orgToken.org_token],
                    segment: selectedValues,
                },
            }))

            // setSliderValue((prevSliderValue) => ({
            //     ...prevSliderValue,
            //     segment: prevSliderValue.segment.filter((_, index) => customSegmentCounts[index]?.id !== e.removedValue.id)
            // }));
            // setActualReach((prevActualReach) => ({
            //     ...prevActualReach,
            //     segment: prevActualReach.segment.filter((_, index) => customSegmentCounts[index]?.id !== e.removedValue.id)
            // }));
        }

        setCustomSegmentCounts(updatedCustomSegmentCounts);


        formik.setFieldValue('targetedAudience.includes.segment', _.isEmpty(updatedCustomSegmentCounts) ? selectedValues : updatedCustomSegmentCounts);

        if (e?.option?.value === 'sendToAll') {
            setTargetGroupData((prevData) => ({
                ...prevData,
                [orgToken.org_token]: {
                    ...prevData[orgToken.org_token],
                    segment: selectedValues,
                },
            }))
            if (e?.removedValue === 'sendToAll') {
                setTargetGroupData((prevData) => ({
                    ...prevData,
                    [orgToken.org_token]: {
                        ...prevData[orgToken.org_token],
                        segment: selectedValues,
                    },
                }))
            // queryClient.setQueryData(['segments'], (data) => {
            //     return data.map(datum => datum.value !== 'sendToAll' ? { ...datum, isDisabled: true } : datum);
            // });
        }

        // if (e?.removedValue?.value === 'sendToAll') {
        //     queryClient.setQueryData(['segments'], (data) => {
        //         return data.map(datum => datum.value !== 'sendToAll' ? { ...datum, isDisabled: false } : datum);
        //     });
        // }

        calculateReach(formik, dispatch);
    };
    }

    const onCsvSelect = (selectedValues, e) => {
        if (e?.option?.data?.extraData?.validationData?.counts) {

            setTargetGroupData((prevData) => ({
                ...prevData,
                [orgToken.org_token]: {
                    ...prevData[orgToken.org_token],
                    csv: selectedValues,
                },
            }));
        }
        console.log("tgData", targetGroupData)
        formik.setFieldValue("targetedAudience.includes.CSV", selectedValues);
        setTimeout(() => {
            checkForInvalidCsv(selectedValues, dispatch)
        }, 100)
    }
    const onCsvUpload = (file) => {
        onClose();
        let tempCSVList = [{ label: file?.fileName, value: file?.id, segmentType: 'CSV', data: file }, ...targetGroupData[orgToken.org_token].csv]
        formik.setFieldValue('targetedAudience.includes.CSV', tempCSVList)
        setTimeout(() => {
            checkForInvalidCsv(tempCSVList, dispatch)
        }, 100)
        // setSelectedCsv()
        queryClient.setQueryData(['uploadedFiles'], (data) => {
            return [{ label: file?.fileName, value: file?.id, data: file }, ...data];

        })
    }

    const onAllSubscribersCheckboxCheck = (e) => {
        if (!(formik.values.targetedAudience.includes.segment.length)) {
            formik.setFieldValue("targetedAudience.includes.segment", [JSON.parse(e.target.value)])
        }
    }

    const cofirmCsvValidationAndUpdate = (list) => {

        onValidationModalClose()
        let updatedSelectedList = updateCsvListAfterValidation(formik?.values?.targetedAudience?.includes?.CSV, list, orgToken.org_token);
        // let updatedCSVList = updateCsvListAfterValidation(uploadedFilesQuery?.data, list, orgToken)
        formik.setFieldValue("targetedAudience.includes.CSV", updatedSelectedList);
        // queryClient.setQueryData(['uploadedFiles'], (data) => {
        //     return updatedCSVList
        // })
        checkForInvalidCsv(updatedSelectedList, dispatch);
    }


    // useEffect(()=>{
    //     calculateReach(formik,dispatch,subscribersCountQuery)
    // },[subscribersCountQuery])
    useEffect(() => {
        if (selectedChannel !== 'push') {
            !segmentQuery.isFetched && segmentQuery.refetch();
            // !uploadedFilesQuery.isFetched && uploadedFilesQuery.refetch();
            //predefinedSegmentCountQuery.refetch();
        }

    }, [selectedChannel, totalReach])
    return (
        <Flex p={3} gap={5} mt={'4vw'} direction={'column'}>
            <input type='hidden'  {...formik.getFieldProps('targetedAudience.includes.segment')} />
            {
                (selectedChannel === 'push') && <FormControl>
                    <FormLabel color={'inputLable'}>Send to (Segments)  </FormLabel>
                    <Select isMulti options={[{ label: 'All Subscribers', value: 'sendToAll', segmentType: 'sendToAll' }]} closeMenuOnSelect={false} value={formik?.values?.targetedAudience?.includes?.segment} onChange={onSegmentSelect} colorScheme='brand' isClearable={false}></Select>
                </FormControl>
            }

            {
                (selectedChannel !== 'push') &&
                <Fragment>
                    <FormControl>

                        <input type='hidden'  {...formik.getFieldProps('targetedAudience.includes.CSV')} />
                        <input type='hidden'  {...formik.getFieldProps('targetedAudience.includes.segment')} />
                        <FormLabel color={'inputLable'}>Send to (Segments)  </FormLabel>
                        {
                            !!segmentQuery.isLoading && <Box display={'grid'} placeItems={'center'}><OvalSpinner height={20} width={20}></OvalSpinner></Box>
                        }
                        {
                            !!segmentQuery?.data && <Select isMulti options={segmentQuery?.data }

                                onChange={onSegmentSelect} colorScheme='brand' isClearable={false}>
                            </Select>
                        }
                    </FormControl>

                    <FormControl>
                        <FormLabel color={'inputLable'}>Send to (CSV)</FormLabel>
                        {
                            !!uploadedFilesQuery.isLoading && <Box display={'grid'} placeItems={'center'}><OvalSpinner height={20} width={20}></OvalSpinner></Box>
                        }
                        {

                            <Fragment>
                                {

                                    <Select isMulti options={!!uploadedFilesQuery?.data && prepareCSVList(uploadedFilesQuery?.data?.uploadedFiles)} closeMenuOnSelect={false} colorScheme='brand' onChange={onCsvSelect} isClearable={false}></Select>

                                }
                                {
                                    !!invalidCsvCount.length && <Text color={'danger'} onClick={onValidationModalOpen} float={'left'} mt={1} cursor={'pointer'}>You have {invalidCsvCount.length} invalid CSV. Click here to validate. </Text>
                                }

                                <Text color={'link'} float={'right'} mt={1} cursor={'pointer'} onClick={onOpen}>Add new CSV+</Text>
                            </Fragment>

                        }
                        <UploadCsvModal isOpen={isOpen} onClose={onClose} channel={selectedChannel} orgToken={orgToken.org_token} onSelect={onCsvUpload}></UploadCsvModal>
                        <CsvValidatorModal isOpen={isValidationModalOpen} onClose={onValidationModalClose} csvList={formik.values.targetedAudience.includes.CSV} channel={selectedChannel} orgToken={orgToken} invalidCsv={invalidCsvCount} onConfirm={cofirmCsvValidationAndUpdate}></CsvValidatorModal>
                    </FormControl>
                </Fragment>
            }
            <Box>
                
                <Reach selectedChannel={selectedChannel} subscribersCountQuery={subscribersCountQuery} setTotalReach={setTotalReach} audience={targetGroupData[orgToken.org_token]} query={subscribersCountQuery} ></Reach>
            </Box>


        </Flex>
    )
}

export default AudienceSelectionTG
