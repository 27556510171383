import React, { useEffect, useState } from 'react';
import { Button, Input, Select, FormControl, FormLabel, Text, Box, VStack, Textarea, TableContainer, Table, UnorderedList, ListItem, useToken } from '@chakra-ui/react';
import axios from 'axios';
import * as _ from 'lodash'
import axiosInstance from '../../Services/axiosInstance';
import TableHead from '../Campaigns/components/TableHead';
import SuppressionListTable from './SuppressionListTable';
import { useToast } from '@chakra-ui/react';

import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import CsvDropzone from './CsvDropzone';
import store from '../../store/store';
import Swal from 'sweetalert2';
import hexToRgba from 'hex-to-rgba'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from '@ajna/pagination'
const SuppressionList = () => {
  const [channel, setChannel] = useState("");
  const [totalCount, setTotalCount] = useState(0)
  const toast = useToast();

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 5, isDisabled: false }, limits: { outer: 1, inner: 1 } })

  const fetchSuppressionList = async (selectedChannel) => {
    let url = `/suppression/getlist/${selectedChannel}?page=${currentPage - 1}&perpage=10`
    let response = await axiosInstance.get(url).catch((e) => console.log(e))
    setSuppresionList(response.data.items)
    setTotalCount(response.data.count)
  }
  const channels = [
    {
      label: "SMS",
      key: "sms",
    },
    {
      label: "Whatsapp",
      key: "whatsapp",
    },
    {
      label: "Email",
      key: "email",
    }
  ]

  const [inputValue, setInputValue] = useState('email');
  const [csvFile, setCsvFile] = useState(null);
  const [getSuppressionList, setSuppresionList] = useState([])
  const [mainColor] = useToken('colors', ['primary.main'])
  const [searchQuery, setSearchQuery] = useState("")

  const handleChannelChange = (e) => {
    setChannel(e.target.value);
    fetchSuppressionList(e.target.value)
    setInputValue('');
  };


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };


  const handleCsvChange = (e) => {
    setCsvFile(e.target.files[0]);
    handleCsvUpload()
  };


  const handleSubmit = async () => {

    const data = channel === 'phone' ? { phone: inputValue } : { email: inputValue };

    try {
      const response = await axiosInstance.post(`/suppression/rest/v1/${channel === 'phone' ? "sms" : "email"}/${btoa(inputValue)}`, data);
      if (response?.data) {
        fetchSuppressionList(channel)
      }
    } catch (error) {
    }
  };
  const filteredActivities = getSuppressionList.filter(item => {
    const query = searchQuery.toLowerCase();
    return (
      item?.email?.toLowerCase().includes(query) ||
      item?.phone?.toLowerCase().includes(query)
    );
  });
  useEffect(() => {
    if (!!searchQuery) {
      setSuppresionList(filteredActivities)
    } else {
      fetchSuppressionList(channel || "email")
    }
  }, [searchQuery, currentPage])
  const deleteFromSuppression = async (contact, channel, fetchSuppressionList) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to remove this contact: ${contact} from suppression?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const resp = await axiosInstance.post(`/suppression/rest/v1/${channel}/${contact}`);
          if (resp) {
            fetchSuppressionList(channel);
            console.log(resp);
            Swal.fire('Deleted!', 'The contact has been removed.', 'success');
          }
        } catch (error) {
          Swal.fire('Error!', 'There was an error removing the contact.', 'error');
        }
      }
    });
  };

  const convertJsonToCsv = (json) => {
    const array = [Object.keys(json[0])].concat(json.map(obj => Object.values(obj)));
    return array
      .map(row => row.join(','))
      .join('\n');
  };


  const downloadCsv = (csvData, fileName) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const org_domain = store.getState().auth.selectedOrg.domain
  console.log("orgdomainnn", org_domain)

  const handleDownload = (jsonData, fileName) => {
    if (!jsonData || jsonData.length === 0) {

      return;
    }
    const csv = convertJsonToCsv(jsonData);
    downloadCsv(csv, fileName);
  };

  const handleCsvUpload = async () => {
  

    const formData = new FormData();
    formData.append('file', csvFile);

    try {

      const response = await axiosInstance.post(`/rest/v1/uploadfile/wzbroadcast/${channel || "email"}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (!!response.data) {
        const res = await axiosInstance.post(`/suppression/rest/v1/${channel || "email"}?mediaUrl=${btoa(response.data.url)}`, {}
        );
        !!res && toast({
          title: "CSV file uploaded.",
          description: "The CSV file has been successfully uploaded and contacts added to the suppression list.",
          status: "success",
          duration: 1000,
          isClosable: true,
          position: "bottom-right"
        });
      }
    } catch (error) {

    }
  };


  return (
    <Box p={5} width={'100%'} >
      <Text as={'heading'}>
        Suppression List
      </Text>
      <VStack>
        <FormControl id="channel" mb={4}>
          <FormLabel>Add to Suppression List {_.upperCase(channel)}</FormLabel>
          <Select placeholder="Select channel" bg={'#ebebeb'} borderRadius={'16px'} onChange={handleChannelChange}>
            {
              channels.map((type) => (
                <option value={type.key} >
                  {type.label}
                </option>
              ))
            }
          </Select>
        </FormControl>

        {channel && (
          <FormControl id={channel} mb={4}>
            <FormLabel>
              {(channel === 'whatsapp' || channel === 'sms') ? 'Phone(s)' : 'Email(s)'}
            </FormLabel>
            <Textarea
              bg={'#ebebeb'} borderRadius={'16px'}
              type={(channel === 'whatsapp' || channel === 'sms') ? 'tel' : 'email'}
              value={inputValue}
              onChange={handleInputChange}
              placeholder={(channel === 'whatsapp' || channel === 'sms') ? 'Enter Number(s)' : ' Enter email(s)'}
            />
        <PrimaryButton my={2} alignSelf={"end"} label={'Add to reject list'} onClick={handleSubmit} mb={4}>

        </PrimaryButton>
          </FormControl>
        )}
{
  <>
  
  
  
  </>

}
{!!channel &&
  <>
        <Text padding={'1rem'} my={2} width={'100%'} textAlign={'center'} borderTop={'1px solid #e4e4e4'} borderBottom={'1px solid #e4e4e4'} >OR</Text>
        <FormControl id="csv" mb={4} w={'100%'} display={'flex'} justifyContent={'space-around'} >
          <VStack>
            <FormLabel>Upload CSV</FormLabel>
            {/* <Input type="file" accept=".csv" onChange={handleCsvChange} /> */}
            <CsvDropzone handleCsvChange={handleCsvChange} />
          </VStack>
          <Box p={5} borderWidth="1px" borderRadius="lg" bg="gray.50" color="gray.800" >
            <Text fontSize="lg" fontWeight="bold" mb={4}>
              Instructions:
            </Text>
            <UnorderedList spacing={3}>
              <ListItem>
                Enter {channel == 'email' ? 'email' : 'phone'} IDs on a new line if you are manually entering the {channel == 'email' ? 'email' : 'phone'} IDs.
              </ListItem>
              <ListItem>
                Upload a <Text as="span" fontWeight="bold">.CSV</Text> (Comma Separated Values) file to import bulk contacts.
              </ListItem>
              <ListItem>
                The <Text as="span" fontWeight="bold">{channel == 'email' ? 'email' : 'phone'} Column No</Text> refers to the column in the list where {channel == 'email' ? 'email' : 'phone'} IDs exist, please choose that.
              </ListItem>
              <ListItem>
                {channel == 'email' ? 'email' : 'phone'}(s) must be valid. Existing {channel == 'email' ? 'email' : 'phone'} IDs will be discarded.
              </ListItem>
            </UnorderedList>
          </Box>
        </FormControl>
  </>
}
  

        
      </VStack>

      {
        !!channel &&
        <VStack mt={"20px"} w={'100%'} >
          <PrimaryButton my={'20px'} alignSelf={'end'} label={'Download List'} onClick={() => handleDownload(getSuppressionList, `suppression_${channel}_report_${org_domain}`)} />
          <TableContainer w={'100%'} >
            <Input type='text' my={2}  placeholder='Search' value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} />
            <Table w={'100%'}>
              <>
                {
                  <>
                    <TableHead headersProps={channel == 'email' ? ['Sr. No.', 'Email', 'Reason', "Date", "Action"] : ['Sr. No.', 'Email', 'Reason', "Date", "Action"]} />
                    {
                      !!getSuppressionList.length == 0 && <Box>
                        <Box mt={'20px'} justifyContent={'center'} width={'100%'} >No Contacts found in suppression list.</Box>

                      </Box>
                    }
                    <SuppressionListTable deleteFromSuppression={deleteFromSuppression} list={getSuppressionList} ></SuppressionListTable>
                  </>
                }
              </>
            </Table>
            {
              !!getSuppressionList.length &&
              <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
                <PaginationContainer alignItems={'center'} justifyContent="end" mt={2}>
                  <PaginationPrevious mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
                  <PaginationPageGroup height={8}  >
                    {
                      pages.map(page => (
                        <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                      ))
                    }
                  </PaginationPageGroup>
                  <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
                </PaginationContainer>
              </Pagination>
            }
          </TableContainer>
        </VStack>
      }
    </Box>
  );
};

export default SuppressionList;
