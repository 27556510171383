import React from 'react'
// import AudienceSelection from "../Campaigns/components/AudienceSelection"
import { useQuery, useQueryClient } from 'react-query';
import { getPredefinedSegmentCount, getSegmentCounts, getSegmentList, getTotalSubscribers, getUploadedFiles, getUploadedFilesAllBanks, updateSegment, getSegmentListAllBanks } from '../../Services/api';
import AudienceSelectionTG from './AudienceSelectionTG'
import { Box, Text, VStack } from '@chakra-ui/react'

export default function AudieceSelection({ formik, targetGroupData, setTargetGroupData }) {
  const uploadedFilesQuery = useQuery('uploadedFiles', {
    queryFn: async () => {
      let res = await getUploadedFilesAllBanks(formik.values.orgList,formik.values.orgList, formik.values.selectedChannel);
      console.log("res", res);
      return res.data;
    },
    enabled: true,
    staleTime: 0
  })
  return (
    <>
      {
        uploadedFilesQuery?.data?.length > 0 && uploadedFilesQuery.data.map((e, i) => (
          <Box >
            <AudienceSelectionTG uploadedFilesQuery={e} targetGroupData={targetGroupData} setTargetGroupData={setTargetGroupData} selectedChannel={"email"} index={i} orgToken={e} orgId={1} formik={formik} showSliders={false} ></AudienceSelectionTG>
          </Box>

        ))

      }
    </>
  )
}
