import { Box, FormLabel, Grid, Input, InputGroup, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { Fragment, useEffect, useState } from 'react'
import axios from "axios"
import { connect } from "react-redux";
import { fetchEmailSavedTemplate, getStriptCredentials, postEmailTemplate } from '../../Services/api';
import StripoHeader from './StripoHeader';
import { Helmet } from "react-helmet"
import { getCustomFonts, getDefaultMergeTags } from '../../_helpers/data_helper';
import SaveTemplateModal from './SaveTemplateModal';
import { useFormik } from 'formik';
import { saveEmailTemplate } from '../../_helpers/formValues';
import { saveEmailTemplateValidation } from '../../_helpers/validationSchema';
import { showSwal } from '../../_helpers/helpers';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../Services/axiosInstance';
import { OvalSpinner } from '../../components/Loaders';

function Editor({ emailId, userId, orgToken }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const navigate = useNavigate();
    const { uuid } = useParams();
    const [formInitialValues, setFormInitialValues] = useState(saveEmailTemplate);
    // const toast = useToast()
    const getTemplateCallback = function (html, css) {
        let stripoBody = {
            templateHtml: !!html ? btoa(unescape(encodeURIComponent(html))) : "",
            templateCss: !!css ? btoa(unescape(encodeURIComponent(html))) : ""
        }
        window.StripoApi.compileEmail(async (error, compileEmailHtml, ampHtml, ampErrors) => {
            if (!!ampHtml) {
                stripoBody["emailHtml"] = btoa(unescape(encodeURIComponent(ampHtml)));
            } else if (!!compileEmailHtml) {
                stripoBody["emailHtml"] = btoa(unescape(encodeURIComponent(compileEmailHtml)));
            }

            if ((!!error && error.length > 0) || (!!ampErrors && ampErrors.length > 0)) {
                console.error('Some error occured while fetching template')
            }
            let saveResponse = await save(stripoBody, uuid);
            if (!_.isEmpty(saveResponse)) {
                toast({ title: `Template ${!!uuid ? 'Updated' : 'Created'}.`, description: `We've ${!!uuid ? 'updated' : 'created'} the template for you.`, status: 'success' });
                navigate("/email/list");
                Swal.close()
            }
        }, true)
        formik.setFieldValue("stripoBody", stripoBody)
        formik.setSubmitting(true);
    }
    const saveTemplate = (values, action) => {
        formik.setSubmitting(true)
        showSwal(Swal, "Are you sure?", `Do you want to ${!!uuid ? 'update' : 'save'} the template.`, true, true, !!uuid ? 'Update' : 'Save', `Don't ${!!uuid ? 'update' : 'save'}`, async () => {

            window.StripoApi.setHiddenPreHeader(values.preheader);
            window.StripoApi.getHiddenPreHeader();
            // formik.setFieldValue("stripoBody", {});
            window.StripoApi.getTemplate(getTemplateCallback)

        }, (res) => {
            !!res.isConfirmed && (() => {
                formik.setSubmitting(false)
                Swal.close()
            })()
            formik.setSubmitting(false)
        })

    }
    const generateFormData = () => {
        let fileFormData = new FormData();
        //fileFormData.append('file', $scope.attachments.file);
        fileFormData.append('description', formik.values.description);
        fileFormData.append('subject', formik.values.subject);
        fileFormData.append('templateType', "stripo");
        if (formik.values?.body) fileFormData.append('body', formik.values.body);
        //fileFormData.append('uuid', getUUIDFromQueryParam());
        fileFormData.append('blockids', []);
        fileFormData.append('uploadedattachments', []);
        fileFormData.append("preheader", formik.values.preheader);
        fileFormData.append("type", formik.values.type);
        return fileFormData;
    }
    const save = async (stripoBody) => {
        //TODO append attachment
        let fileFormData = generateFormData();
        if (!!stripoBody.templateHtml || stripoBody.templateCss || stripoBody.emailHtml) {
            for (let [key, value] of Object.entries(stripoBody)) {
                let blob = new Blob([value], {
                    type: "text/plain"
                });
                fileFormData.append(key, blob);
            }
        }
        fileFormData.append('status', 'active');
        !!formik.values?.attachments.length && formik.values?.attachments.forEach(function (attachment, index) {
            fileFormData.append('file', attachment);
        });
        let saveResponse = await postEmailTemplate(fileFormData, uuid).catch(error => {
            console.log(error)
            !!error && toast({ title: 'Oops', description: `Something went wrong while  creating the template. Please try again.`, status: 'error' });
            formik.setSubmitting(false)
            Swal.close()
        });
        return saveResponse


    }
    const formik = useFormik({
        initialValues: { ...formInitialValues },
        validationSchema: saveEmailTemplateValidation,
        enableReinitialize: true,
        onSubmit: saveTemplate
    })

    let initPlugin = (template) => {
        console.log("called initial")
        const apiRequestData = {
            emailId: emailId,
            userId: userId
        };

        const script = document.createElement("script");
        script.id = 'stripoScript';
        script.type = 'text/javascript';
        script.src = 'https://plugins.stripo.email/static/latest/stripo.js';
        script.onload = function () {
            window.Stripo.init({
                settingsId: "striptEditorContainer",
                previewId: "stripoPreviewContainer",
                locale: 'en',
                html: template.html,
                css: template.css,
                apiRequestData: apiRequestData,
                userFullName: 'Plugin Demo User',
                codeEditorButtonId: 'codeEditor',
                undoButtonId: 'undoButton',
                redoButtonId: 'redoButton',
                externalImagesLibrary: window.ExternalImagesLibrary,
                ignoreClickOutsideSelectors: '#externalImagesLibrary',
                editorFonts: {
                    "showDefaultStandardFonts": true,
                    "showDefaultNotStandardFonts": true,
                    "customFonts": getCustomFonts()
                },
                getAuthToken: async function (callback) {
                    let tokenRes = await getStriptCredentials();
                    console.log("token response", tokenRes)
                    if (!!tokenRes && !!tokenRes.data && !!tokenRes.data.token) {
                        callback(tokenRes.data.token);
                    } else {
                        console.log('Please check your stripo credentials in the config file(application.conf)');
                    }
                },
                onTemplateLoaded: function () {
                    console.log('Loaded')
                    //$(".sripo-watermark").remove();
                    //getPreheaders()
                },
                mergeTags: [
                    {
                        /*"category": "Mkt",*/
                        "entries": [] || getDefaultMergeTags()
                    }
                ],
            })
        }
        document.body.appendChild(script);


    }
    let fetchInitialTemplate = async () => {
        let htmlLink = 'https://media.orbisdata.ai/email_templates/predefined/categories/Basic-Templates/Empty-Template/Empty-Template.html';
        let cssLink = 'https://media.orbisdata.ai/email_templates/predefined/categories/Basic-Templates/Empty-Template/Empty-Template.css';
        let htmlRes = await axios.get(htmlLink);
        let cssRes = await axios.get(cssLink);
        !!htmlRes?.data && !!cssRes?.data ? initPlugin({ html: htmlRes.data, css: cssRes.data }) : console.log("template not found")

    };
    const previewTemplate = () => {
        window.StripoApi.compileEmail(function (error, html, ampHtml) {
            window.ExternalPreviewPopup.openPreviewPopup(html, ampHtml)
        })
    };
    let b64DecodeUnicode = function (str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
    let fetchSavedTemplate = async () => {
        let templateResp = await fetchEmailSavedTemplate(uuid).catch(error => {
            toast({ title: `Oops!`, description: `Problem in fetching saved template.`, status: 'error' });
            navigate("/email/edit");
        });
        !_.isEmpty(templateResp) && _.has(templateResp, "data") && _.isEqual(templateResp.data?.status, "success") && (() => {
            // setFormInitialValues(setFormValuesFromResponse(templateResp.data))
            let dt = {};
            let data = templateResp.data
            dt['description'] = data.description;
            dt['subject'] = data.subject;
            dt['body'] = data.body;
            let extraData = data?.extraData;
            dt['preheader'] = extraData?.preheaders || "";
            if (!!extraData && extraData?.templateType === 'stripo' && !!extraData?.stripoData && (!!extraData.stripoData.templateHtml || !!extraData.stripoData.templateCss)) {
                dt['templateType'] = extraData.templateType;
                let decodedTemplateHtml = extraData.stripoData.templateHtml;
                let decodedTemplateCss = extraData.stripoData.templateCss;
                try {
                    decodedTemplateHtml = window.atob(extraData.stripoData.templateHtml);
                    decodedTemplateHtml = b64DecodeUnicode(extraData.stripoData.templateHtml);
                } catch (e) {
                    console.log("error in decoding the template")
                }
                try {
                    decodedTemplateCss = window.atob(extraData.stripoData.templateCss);
                    decodedTemplateCss = b64DecodeUnicode(extraData.stripoData.templateCss);
                    dt['stripoBody'] = {};
                    dt.stripoBody['templateCss'] = extraData.stripoData.templateCss;
                } catch (e) {
                    console.log("error in decoding template css")
                }
                initPlugin({
                    html: decodedTemplateHtml,
                    css: decodedTemplateCss
                })
                dt['blockids'] = !!data.blockids ? JSON.parse(data.blockids) : '';
                setFormInitialValues({ ...saveEmailTemplate, ...dt });
                //formik.resetForm()

            } else {
                navigate("/email/edit")
            }
        })()

    }
    useEffect(() => {
        !!uuid ? fetchSavedTemplate() : fetchInitialTemplate();
        window.mktConfig = {
            apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
            orgToken: orgToken,
            axios: axiosInstance
        }
    }, [])
    return (
        <Fragment>
            <StripoHeader previewTemplate={previewTemplate} onOpen={onOpen}></StripoHeader>
            <Helmet>
                <link href={`${process.env.PUBLIC_URL}/css/externalPreview.css`} rel="stylesheet"></link>
                <script src={`${process.env.PUBLIC_URL}/js/stripoPreview.js`}></script>
                <script src={`${process.env.PUBLIC_URL}/js/email-editor-image-library.js`}></script>
            </Helmet>
            <Box mt={20} position={'relative'}>
                {/* <Grid templateColumns={'1fr 1fr 1fr 1fr'} gap={5}>
                    <InputGroup display={'flex'} flexDirection={'column'}>
                        <FormLabel color={'inputLabel'}>Name</FormLabel>
                        <Input></Input>
                    </InputGroup>
                    <InputGroup display={'flex'} flexDirection={'column'}>
                        <FormLabel color={'inputLabel'}>Subject</FormLabel>
                        <Input></Input>
                    </InputGroup>
                    <InputGroup display={'flex'} flexDirection={'column'}>
                        <FormLabel color={'inputLabel'}>Preheader Text</FormLabel>
                        <Input></Input>
                    </InputGroup>
                </Grid> */}
                <Box id="striptEditorContainer" float={'left'} w={'400px'}>
                    <Box position={'absolute'} w={'100%'} h={'300px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <OvalSpinner height={100} width={100}></OvalSpinner>
                    </Box>
                </Box>
                <Box id='stripoPreviewContainer' w={'calc(100% - 400px)'} float={'left'}></Box>
            </Box>
            <SaveTemplateModal isOpen={isOpen} onClose={onClose} formik={formik} uuid={uuid}></SaveTemplateModal>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    console.log("state", state)
    return {
        ...state,
        emailId: state.auth.user_data.email,
        userId: state.auth.user_data.id,
        orgToken: state.auth.selectedOrg.token

    }
}
export default connect(mapStateToProps)(Editor)